const swiper = new Swiper(".reviews-slider", {
  slidesPerView: "auto",
  loop: true,
  pagination: {
    el: ".reviews-slider .swiper-pagination",
    type: "bullets",
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: ".reviews-slider .swiper-button-next",
    prevEl: ".reviews-slider .swiper-button-prev",
  },
});

const workSteps = new Swiper(".work-steps__slider", {
  spaceBetween: 40,
  slidesPerView: 1.21,
  loop: true,
  breakpoints: {
    992: {
      spaceBetween: 28,
    },
    700: {
      slidesPerView: 1.09,
      spaceBetween: 12,
    },
    320: {
      slidesPerView: 1.06,
      spaceBetween: 12,
    },
  },
  pagination: {
    el: ".work-steps__slider .swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: ".work-steps__slider .swiper-button-next",
    prevEl: ".work-steps__slider .swiper-button-prev",
  },
});
function priceSlider() {
  const price = new Swiper(".price-content", {
    spaceBetween: 12,
    slidesPerView: 2.007,
    loop: true,
    breakpoints: {
      760: {
        slidesPerView: 2.007,
      },
      320: {
        slidesPerView: 1.06,
      },
    },
    pagination: {
      el: ".price-content .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  });
}

function benefitsSlider() {
  const benefits = new Swiper(".benefits__wrapper", {
    spaceBetween: 12,
    slidesPerView: 1.07,
    loop: true,
    pagination: {
      el: ".benefits__wrapper .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  });
}
function worksContentSlider() {
  const worksContent = new Swiper(".work-content__wrapper", {
    spaceBetween: 12,
    slidesPerView: 1.07,
    loop: true,
    pagination: {
      el: ".work-content__wrapper .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  });
}
function cooperationSlider() {
  const worksContent = new Swiper(".cooperation__wrapper", {
    spaceBetween: 12,
    slidesPerView: 1.07,
    loop: true,
    pagination: {
      el: ".cooperation__wrapper .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  });
}
$(document).ready(function () {
  if ($(window).width() < 660) {
    benefitsSlider();
    worksContentSlider();
    cooperationSlider();
  }
  if ($(window).width() < 992) {
    priceSlider();
  }
});

$(window).on("resize", function () {
  if ($(window).width() < 992) {
    priceSlider();
  }
  if ($(window).width() < 660) {
    benefitsSlider();
    worksContentSlider();
    cooperationSlider();
  }
});
const tabletSlider = new Swiper(".case__tablet-slider .slider", {
  spaceBetween: 40,
  slidesPerView: 1,
  loop: true,
  breakpoints: {
    992: {
      spaceBetween: 40,
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 1.06,
      spaceBetween: 8,
    },
    320: {
      slidesPerView: 1.02,
      spaceBetween: 4,
    },
  },
  pagination: {
    el: ".case__tablet-slider .swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: ".case__tablet-slider .swiper-button-next",
    prevEl: ".case__tablet-slider .swiper-button-prev",
  },
});
const mobileSlider = new Swiper(".case__mobile-slider .slider", {
  spaceBetween: 90,
  slidesPerView: 3,
  loop: true,
  breakpoints: {
    1700: {
      spaceBetween: 90,
    },
    1360: {
      spaceBetween: 7,
      slidesPerView: 3,
    },
    800: {
      spaceBetween: 7,
      slidesPerView: 2,
    },
    320: {
      spaceBetween: 4,
      slidesPerView: 1.02,
    },
  },
  pagination: {
    el: ".case__mobile-slider .swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: ".case__mobile-slider .swiper-button-next",
    prevEl: ".case__mobile-slider .swiper-button-prev",
  },
});

function beforeAfterSlider() {
  let sliWidth = $(".sl-container-wrap").width();
  var $dragMe = $(".dragme"),
    $container = $(".sl-container"),
    $viewAfter = $(".view-after");

  $dragMe.draggable({
    containment: "parent",
    drag: function () {
      $viewAfter.css({
        width: parseFloat($(this).css("left")) + 10,
      });
    },
  });
  $container.on("click", function (event) {
    var eventLeft = event.pageX - $container.offset().left;

    if (eventLeft > sliWidth) {
      eventLeft = sliWidth;
      $dragMe.animate(
        {
          left: sliWidth - 20,
        },
        "slow",
        "linear"
      );
      $viewAfter.animate(
        {
          width: sliWidth - 20,
        },
        "slow",
        "linear"
      );
    } else {
      $dragMe.animate(
        {
          left: eventLeft,
        },
        "slow",
        "linear"
      );
      $viewAfter.animate(
        {
          width: eventLeft + 10,
        },
        "slow",
        "linear"
      );
    }
  });
  animateTo("50%");
  function animateTo(_left) {
    $(".sl-container .view-after img").attr(
      "style",
      `width: ${sliWidth}px; max-width: ${sliWidth}px`
    );
    $dragMe.animate(
      {
        left: _left,
      },
      "slow",
      "linear"
    );
  }
}
$(document).ready(function () {
  beforeAfterSlider();
  if ($(window).width() < 660) {
    $(".comparison_desktop").remove();
  }
});

$(window).on("resize", function () {
  beforeAfterSlider();
  if ($(window).width() < 660) {
    $(".comparison_desktop").remove();
  }
});
